
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-debit-notes-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      debitNotes: [],
    };
  },
  setup() {
    const { useReusableNavigation } = ResusableFunctions();

    return { useReusableNavigation };
  },
  mounted() {
    this.getdebitNotes();
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getdebitNotes() {
      //Fetch list of all policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_DEDIT_NOTES_ROUTE)
          .then(({ data }) => {
            //Assign data to varibales
            this.debitNotes = data.data;
            setTimeout(() => {
              this.displayDatatable();
            }, 3000);
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }, 3000);
    },
  },
});
