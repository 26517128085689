
import { defineComponent, onMounted } from "vue";
import DebitNotesTable from "@/components/finance/DebitNotesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "finance-debit-notes",
  components: {
    DebitNotesTable
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Debit Notes", ["Finance"]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title = "Finance: Debit Notes List | " + process.env.VUE_APP_TITLE;
  }
});
